
















import { Component, Vue } from 'vue-property-decorator'

import CenteredMedium from '@/layouts/Panels/CenteredMedium66.vue'
import NavMenu from '@/partials/components/NavMenu.vue'

@Component({
  components: {
    NavMenu,
    CenteredMedium
  }
})
export default class MedicalPatientsLayout extends Vue {
  availableRoutes: AvailableRoute[] = []

  created () {
    const baseUrl = `/medical/patients/${this.$route.params.patientId}`
    this.availableRoutes = [
      {
        label: 'Ficha Cadastral',
        path: baseUrl + '/registration-form'
      },
      {
        label: 'Avaliações',
        path: baseUrl + '/evaluations'
      },
      {
        label: 'Documentos',
        path: baseUrl + '/documents'
      }
    ]
  }
}
